import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import SpareMicLogo from '../../assets/images/SpareMicLogo.png';
import AppStoreDownload from '../../assets/images/Download_on_the_App_Store_Badge_US-UK_wht_092917.png';
import Apple from '../../assets/images/apple.png';
import ArtworkImage from '../../assets/images/Artwork.png';
import ConnectImage from '../../assets/images/Connect.png';
import ExportImage from '../../assets/images/Export.png';
import AccessImage from '../../assets/images/Access.png';
import Terms from '../../assets/pdfs/TermsOfUse.pdf';
import Privacy from '../../assets/pdfs/PrivacyPolicy.pdf';
import PressRelease from '../../assets/pdfs/PressRelease.pdf';

import NoFile from '../../assets/images/noFile.png';
import ShortEditing from '../../assets/images/shortEditing.png';
import SingleFailure from '../../assets/images/singleFailure.png';
import Easy from '../../assets/images/easy.png';
import ExportShare from '../../assets/images/exportShare.png';
import Microphone from '../../assets/images/microphone.png';
import Mirror from '../../assets/images/mirror.png';
import Teleprompter from '../../assets/images/teleprompter.png';
import Message from '../../assets/images/message.png';
import TimeSync from '../../assets/images/timesync.png';

import TikTok from '../../assets/images/tiktok.png';
import Twitter from '../../assets/images/twitter.png';
import YouTube from '../../assets/images/youtube.png';
import Instagram from '../../assets/images/instagram.png';
import Facebook from '../../assets/images/facebook.png';

import { withFirebase } from '../../firebase/Firebase';
import { logEvent } from 'firebase/analytics';

import './sparemic.styles.scss';

class SpareMic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        EMAIL: '',
        FNAME: '',
        LNAME: '',
        firebase: props.firebase,
        appStoreLink: 'https://apps.apple.com/us/app/spare-mic-wireless-mic-app/id6443896041'
    }

    this.subscribeRef = React.createRef();
  }

  scrollTo(element) {
      if (element) {
          element.scrollIntoView({ behavior: "smooth", top: element.scrollHeight})
      }
  }

  // takes an event, gets the target value and name and sets it to the state, this is to allow
  // form component to be reused
  handleChange = event => {
      // pull the value and name of the event target which is the input value
      const { value, name } = event.target;

      // then set the state of that name to the value
      this.setState({ [name]: value })
  }

  handleSubmit = event => {
      this.setState({
          EMAIL: '',
          FNAME: '',
          LNAME: ''
      })
  }

  handleDownloadClick = async event => {
    // prevent the anchor from its default behavior
    event.preventDefault();

    await logEvent(this.state.firebase.analytics, 'download_link_clicked', {
        route: '/sparemic'
    });

    // navigate to the link provided after the log event using a delay of 3 seconds
    setTimeout(() => {
        window.location.href = this.state.appStoreLink; // event.currentTarget.href;
    }, 3000)
  }

  render() {
        return (
          <div className="spare-mic">
                <div className='header'>
                    <NavLink to='/sparemic' className='logo-container'>
                        <img src={SpareMicLogo} className='logo' alt='logo' loading='eager' height='50'/>
                        <span className='logo-text'> Spare Mic </span>
                    </NavLink>
                    <div className='options'>
                        <div className='option' onClick={() => { this.scrollTo(this.whyRef) }}>
                            Why
                        </div>
                        <div className='option' onClick={() => { this.scrollTo(this.howItWorksRef) }}>
                            How to
                        </div>

                        <div className='option' onClick={() => { this.scrollTo(this.faqRef) }}>
                            FAQ
                        </div>

                        <NavLink className='option' to='/pricing'> Pricing </NavLink>

                        <div className='option' onClick={() => { this.scrollTo(this.subscribeRef) }}>
                            Subscribe
                        </div>

                        <button className='option' onClick={this.handleDownloadClick}> Download </button>
                        {/* <div className='option'>
                            <a href="https://apps.apple.com/us/app/spare-mic-wireless-mic-app/id6443896041"
                                onClick={this.handleDownloadClick}>
                                Download
                            </a>
                        </div> */}
                    </div>
                    <div className="altoption">
                      <div className='option' onClick={() => { this.scrollTo(this.howItWorksRef) }}>
                          How to
                      </div>
                      <NavLink className='option' to='/pricing'> Pricing </NavLink>
                      <button className='option' onClick={this.handleDownloadClick}> Download </button>
                    </div>
                </div>
                <div className="banner">
                    <div className="banner-text">
                      <div className="banner-image-container">
                        <img src={ArtworkImage} alt="Spare Mic Artwork" className='banner-image'/>
                      </div>
                      <div className="banner-logo-container">
                        <img src={SpareMicLogo} alt="Spare Mic Logo" className='banner-logo'/>
                        <h1>Spare Mic</h1>
                        <h3>Use a spare iOS device as a wireless microphone when recording videos</h3>
                        {/* <a  href="https://apps.apple.com/us/app/spare-mic/id6443896041"> <img  alt="Spare Mic App Store Page" src={AppStoreDownload} className='banner-download'/> </a> */}
                        <button onClick={this.handleDownloadClick}> <img  alt="Spare Mic App Store Page" src={AppStoreDownload} className='banner-download'/> </button>
                        <a href="https://www.producthunt.com/posts/spare-mic-wireless-mic-app-beta?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-spare&#0045;mic&#0045;wireless&#0045;mic&#0045;app&#0045;beta" target="blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=367837&theme=light" alt="Spare ;Mic ;&#0045; ;Wireless ;Mic ;App ;beta - Use ;a ;second ;iPhone&#0047;iPad ;as ;a ;wireless ;mic ;for ;your ;videos | Product Hunt" className="product-hunt" width="300" height="66" /></a>
                        {/* <CustomButton type="button" isVentButton visibility onClick={() => { this.scrollTo(this.subscribeRef) }}> Read White Paper </CustomButton> */}
                      </div>
                      <div>

                      </div>
                      <div></div>
                    </div>
                </div>

                <div className="container container-black">
                    <img src={Apple} alt="Apple Logo"/>
                    <h2> Apple Entrepreneur Camp </h2>
                    <h3> Spare Mic was proudly selected for the prestigious Apple Entrepreneur Camp in 2023. </h3>
                    <a href="https://developer.apple.com/entrepreneur-camp/alumni/"> Learn More </a>
                </div>

                <div className="container container-gray" ref={ref => this.howItWorksRef = ref}>
                    <h4> How to</h4>
                    <h2> Watch this 1 minute video </h2>
                    <iframe className='videoFrame' width="1120" height="630" src="https://www.youtube.com/embed/ki7VJSgCWNA" title="How to use Spare Mic on iPhone and iPad" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <h3> Step by step guide on how to turn your iOS devices into a wireless mic </h3>
                    <div className="container-grid">
                        <div className="grid-item content1">
                            <img src={ConnectImage} alt='connect' loading='eager'/>
                            <h2> Connect </h2>
                            <h3> With WiFi and Bluetooth enabled &#38; the app open on both devices, tap the connect button and select the second device’s name from the list </h3>
                        </div>
                        <div className="grid-item content2">
                            <img src={ExportImage} alt='preview and export' loading='eager'/>
                            <h2> Preview &#38; Export  </h2>
                            <h3> To preview your videos, start by selecting a video then tap the play button or tap export to save your videos to your photos library* </h3>
                        </div>
                        <div className="grid-item content3">
                            <img src={AccessImage} alt='Enable Access' loading='eager'/>
                            <h2> Enable Access </h2>
                            <h3> Spare Mic requires access to your camera &#38; microphone to perform core functionalities </h3>
                        </div>
                    </div>
                    <h5>* removing watermark requires an in-app purchase</h5>
                </div>

                <div className="container container-black" ref={ref => this.whyRef = ref}>
                    <h4> Why use the Spare Mic app? </h4>
                    <h2> The best wireless mic is the one you have with you </h2>
                    <h3> Spare Mic provides you with benefits that no other solution can </h3>
                    <div className="container-grid-cinco">
                        <div className="grid-item content1">
                            <img src={NoFile} alt='connect' loading='eager'/>
                            <h2> No File transfers </h2>
                            <h3> The audio is streamed directly to the other device, no need to transfer files between devices. </h3>
                        </div>
                        <div className="grid-item content2">
                            <img src={ShortEditing} alt='preview and export' loading='eager'/>
                            <h2> No More Syncing  </h2>
                            <h3> Your audio is auto synced. No need to stitch your video and audio together in an editor. </h3>
                        </div>
                        <div className="grid-item content3">
                            <img src={SingleFailure} alt='Enable Access' loading='eager'/>
                            <h2> Single Point of Failure </h2>
                            <h3> Interruptions such as phone call or system alerts stops recording on both devices. </h3>
                        </div>
                        <div className="grid-item content4">
                            <img src={Easy} alt='Enable Access' loading='eager'/>
                            <h2> Super Intuitive </h2>
                            <h3> We have made the app easy to use and provide helpful guides and online support.</h3>
                        </div>
                        <div className="grid-item content5">
                            <img src={ExportShare} alt='Enable Access' loading='eager'/>
                            <h2> Export and Share </h2>
                            <h3> You can export your videos to your photos library and then share them with the world.* </h3>
                        </div>
                    </div>
                    <div className="container-grid-cinco">
                        <div className="grid-item content1">
                            <img src={Microphone} alt='connect' loading='eager'/>
                            <h2> Mic Switcher </h2>
                            <h3> Switch between front, back, or bottom microphones. </h3>
                        </div>
                        <div className="grid-item content2">
                            <img src={Mirror} alt='preview and export' loading='eager'/>
                            <h2> Mirror Camera </h2>
                            <h3> Flip the camera horizontally to capture desired selfie video. </h3>
                        </div>
                        <div className="grid-item content3">
                            <img src={Teleprompter} alt='Enable Access' loading='eager'/>
                            <h2> Teleprompter </h2>
                            <h3> Stay on script while recording with an adjustable speed teleprompter. </h3>
                        </div>
                        <div className="grid-item content4">
                            <img src={Message} alt='Enable Access' loading='eager'/>
                            <h2> Foldback Message </h2>
                            <h3> Send feedback messages to the remote device operator for adjustments, such as moving microphone closer.</h3>
                        </div>
                        <div className="grid-item content5">
                            <img src={TimeSync} alt='Enable Access' loading='eager'/>
                            <h2> TimeSync </h2>
                            <h3> Achieve ultra-low latency audio with synced time codes. </h3>
                        </div>
                    </div>
                </div>

                <div className="container container-gray" ref={ref => this.faqRef = ref}>
                    <h4> Frequently Asked Questions </h4>
                    <h2> Have a question in mind, it may already have an answer </h2>
                    <h3> If you do not find an answer to your questions, please Contact Us or use our <a alt="Support Portal" href="https://synergietech.ladesk.com/">Support Portal</a> </h3>
                    <ul className="accordion">
                        <li>
                            <input type="radio" name="accordion" id="first"/>
                            <label htmlFor="first">Is the audio auto Synced on the second device?</label>
                            <div className="content">
                                <p>Yes, the audio is auto synced on the device you use as the camera. Audio is streamed directly to that device and requires no file transfers or editing under optimal conditions.</p>
                            </div>
                        </li>
                        <li>
                            <input type="radio" name="accordion" id="second"/>
                            <label htmlFor="second">Can I install Spare Mic on my iPad?</label>
                            <div className="content">
                                <p>Yes, Spare Mic works on any device that is iOS 9+ compatible. i.e. iPhones 4S (2011) and up, iPad 2 and up, iPod Touch (5th and 6th generation).</p>
                            </div>
                        </li>
                        <li>
                            <input type="radio" name="accordion" id="third"/>
                            <label htmlFor="third">Will the audio suffer latency?</label>
                            <div className="content">
                                <p>Although Spare Mic does its best to reduce latency, latency could occur because of multiple factors; the distance between the devices, the processor on the device, how congested the wireless network is, e.t.c. However, if there is an audio latency on your videos, it requires minimal editing to adjust audio sync issues.</p>
                            </div>
                        </li>
                        <li>
                            <input type="radio" name="accordion" id="fourth"/>
                            <label htmlFor="fourth">Do you have to be on Wi-Fi to use the App?</label>
                            <div className="content">
                                <p>The Spare Mic app requires users to turn on Wi-Fi and Bluetooth so it can discover nearby devices and create a local network between them. You do not need to join your home or work Wi-Fi networks just enable Wi-Fi and the app does the rest. The app works outdoors as long as the devices are within 10 meters/30 feet of each other.</p>
                            </div>
                        </li>
                        <li>
                            <input type="radio" name="accordion" id="fifth"/>
                            <label htmlFor="fifth">Nothing happens when I tap the connect button, why don’t I see my other device?</label>
                            <div className="content">
                                <p>Make sure the app is installed on both devices, both devices have Wi-Fi and Bluetooth turned on and the Spare Mic app is open on both screens then tap the Connect button on one of the two devices. You should see your second device listed.</p>
                            </div>
                        </li>
                    </ul>
                    <h5>New features, bug fixes and updates are constantly being added, FAQs may not always be up to date</h5>
                </div>

                <div className="container container-black" ref={ref => this.subscribeRef = ref}>
                    <div className="container-grid-duo">
                        <div></div>
                        <div>
                            <h4> Stay Updated </h4>
                            <h2> Be one of the first to know </h2>
                            <h3> Subscribe for Exclusive Deals, Latest Features, and Beta Releases Access.</h3>
                            <div id="mc_embed_signup">
                                <form action="https://app.us21.list-manage.com/subscribe/post?u=182eae2dbee824432342ad618&amp;id=2e57b5776a&amp;f_id=003aebe6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                    <div id="mc_embed_signup_scroll">

                                        <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                                            <div className="mc-field-group">
                                                <label htmlFor="mce-EMAIL">Email Address
                                                    <span className="asterisk">*</span>
                                                </label>
                                                <input onChange={this.handleChange} type="email" value={this.state.EMAIL} name="EMAIL" className="required email" id="mce-EMAIL"/>
                                            </div>
                                            <div className="mc-field-group">
                                                    <label htmlFor="mce-FNAME">First Name </label>
                                                    <input onChange={this.handleChange} type="text" value={this.state.FNAME} name="FNAME" className="" id="mce-FNAME"/>
                                            </div>
                                            <div className="mc-field-group">
                                                <label htmlFor="mce-LNAME">Last Name </label>
                                                <input onChange={this.handleChange} type="text" value={this.state.LNAME} name="LNAME" className="" id="mce-LNAME"/>
                                            </div>
                                        </div>
                                        <div id="mce-responses" className="clear">
                                            <div className="response" id="mce-error-response" style={{display:'none'}}></div>
                                            <div className="response" id="mce-success-response" style={{display:'none'}}></div>
                                        </div>
                                        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input onChange={this.handleChange} type="text" name="b_caf4e9280d2014267f83c69f0_a925a5f0c9" tabIndex="-1" value=""/></div>
                                        <div className="clear center"><input onSubmit={this.handleSubmit} type="submit" value="Let's Go" name="subscribe" id="mc-embedded-subscribe" className="button"/></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container container-gray">
                    <div className='footer'>
                        <div>
                            <h2> Write a review </h2>
                            <a href="https://www.producthunt.com/products/spare-mic-wireless-mic-app-beta/reviews?utm_source=badge-product_review&utm_medium=badge&utm_souce=badge-spare&#0045;mic&#0045;wireless&#0045;mic&#0045;app&#0045;beta" target="_blank" rel="noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/product_review.svg?product_id=506156&theme=light" alt="Spare Mic ;&#0045; ;Wireless ;Mic ;App ;beta - Use ;a ;second ;iPhone&#0047;iPad ;as ;a ;wireless ;mic ;for ;your ;videos | Product Hunt" width="250" height="54" /></a>
                            <h5>Your reviews may be feature on our website</h5>
                        </div>
                        <div className='links'>
                            <h2> Connect with us </h2>
                            <a className='option' href="https://www.tiktok.com/@sparemic"> <img alt="TikTok" src={TikTok}/> </a>
                            <a className='option' href="https://twitter.com/SpareMic"> <img alt="Twitter" src={Twitter}/> </a>
                            <a className='option' href="https://www.youtube.com/@sparemicapp"> <img alt="YouTube" src={YouTube}/> </a>
                            <a className='option' href="https://www.instagram.com/sparemicapp/"> <img alt="Instagram" src={Instagram}/> </a>
                            <a className='option' href="https://www.facebook.com/sparemic/"> <img alt="Facebook" src={Facebook}/> </a>
                        </div>
                    </div>
                </div>

                <div className="container container-gray">
                    <div className='footer'>
                        <div className='links'>
                            {/* <NavLink className='option' to='/about'> About </NavLink>
                            <NavLink className='option' to='/contact'> Contact </NavLink> */}
                            <a className='option' href={Terms}> Terms of Use  </a>
                            <a className='option' href={Privacy}> Privacy Policy </a>
                            <a className='option' href={PressRelease}>Press</a>
                        </div>

                        <div className='copyright'>Spare Mic <br/>Copyright © 2025 Synergie Tech, Inc. <br/>All Rights Reserved </div>
                    </div>
                </div>
          </div>
        )
  }
}

export default withRouter(withFirebase(SpareMic));
